import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import header from '../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import LinkBack from '../../components/link-back'

class MonitoringReportsTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBasicPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`} />

          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>{post.title}</h1>
          </div>

          <Container className="news mt-5 mb-5">
            <LinkBack to={'/data-hub'} title={'back to Data hub'} />

            <Row>
              <Col>
                {post.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.text.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default MonitoringReportsTemplate

export const pageQuery = graphql`
  query MonitoringReportsPageBySlug {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBasicPage(slug: { eq: "monitoring-reports" }) {
      title
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
